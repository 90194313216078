.selectionRow {
    margin-top: 1em;
}

.radioSelection {
    border: 0.15em solid #466eff;
    padding: 0.05em 0.05em 0.05em 0.05em;
    width: 3em;
    text-align: center;
    margin-right: 0.45em;
    cursor: pointer;
    background-color: #ffffff;
}

.radioSelectionYes {
    border: 0.15em solid #466eff;
    padding: 0.05em 0.05em 0.05em 0.05em;
    width: 3em;
    text-align: center;
    margin-right: 0.45em;
    cursor: pointer;
    background-color: #466eff;
    color: #ffffff;
}

.radioSelectionNo {
    border: 0.15em solid #ffd756;
    padding: 0.05em 0.05em 0.05em 0.05em;
    width: 3em;
    text-align: center;
    margin-right: 0.45em;
    cursor: pointer;
    background-color: #ffd756;
}

.multiSelection {
    border: 0.15em solid #466eff;
    padding: 0.05em 0.05em 0.05em 0.05em;
    width: 3em;
    text-align: center;
    margin-right: 0.45em;
    cursor: pointer;
    background-color: #ffffff;
}

.multiSelectionYes {
    border: 0.15em solid #466eff;
    padding: 0.05em 0.05em 0.05em 0.05em;
    width: 3em;
    text-align: center;
    margin-right: 0.45em;
    cursor: pointer;
    background-color: #466eff;
    color: #ffffff;
}

.resetButton, .submitButton, .exportButton {
    border: 0.15em solid #466eff;
    text-align: center;
    cursor: pointer;
    box-shadow: 0.25em 0.25em 0.25em 0em #484848;
}

.resetButton:hover, .submitButton:hover, .exportButton:hover {
    border: 0.15em solid #466eff;
    text-align: center;
    cursor: pointer;
    background-color: #466eff;
    color: #ffffff;
    box-shadow: 0.25em 0.25em 0.25em 0em #484848;
}

.resetButton:active, .submitButton:active, .exportButton:active {
    top: 0.25em;
    border: 0.15em solid #466eff;
    text-align: center;
    cursor: pointer;
    background-color: #466eff;
    color: #ffffff;
    box-shadow: 0.05em 0.05em 0.25em 0em #484848;
}

.selectionTitle { }

.sectionDivide {
    margin-top: 3em;
}

.smallSectionDivide {
    margin-top: 1em;
}

.tooltipIcon {
    height: 15px;
    width: 15px;
}

.tooltipStyle {
    color: #000000;
    font-size: 0.85em;
    font-style: italic;
    display: none;
}

.helpBoxOutline {
    border: 0.15em dotted #ffac2b;
    margin-top: 0.3em;
}

.rightSpacer {
    margin-right: 1em;
}

.error {
    display: none;
    font-size: 0.85em;
    color: #ff0049;
    font-style: italic;
}

.calculatedRiskLabel {
    text-align: right;
}

.calculatedRisk {
    border: 0.15em solid red;
    margin-left: 0.25em;
    padding: 0.25em;
    overflow: hidden;
    text-align: center;
}

#appHeader {
    text-align: center
}

#appFooterDivider {
    margin-top: 3em;
    border-bottom: #c7c7c7 solid;
    border-width: 0.1em;
}

#appFooter {
    text-align: center;
    font-size: 0.75em;
}

#enterButton {
    text-align: center
}

#lengtOfStayInput, #lengthOfStayLabel, #ageInput, #ageLabel {
    padding-left: 0px;
}

#woundClassification, #asaSelection { }

#selectionNotice {
    font-style: italic;
    color: #ff6c00;
}

#developmentBanner {
    background-color: #ff0062;
    color: #fff;
    font-weight: 700;
    text-align: center;
    width: 100vw;
}